<template>
  <div>
    <b-container class="white-card">
      <h1>Tyresö Bostäder Kölerer</h1>
      <b-row>
        <b-col sm class="colors NCS0502-Y">NCS 0502-Y</b-col>
        <b-col sm class="colors NCS0500-N">NCS 0500-N</b-col>
        <b-col sm class="colors NCS2500-N">NCS 2500-N</b-col>
        <b-col sm class="colors NCS1000-N">NCS 1000-N</b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "TyresoBostaderView"
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.colors {
  padding: 50px;
  text-align: center;
  margin: 10px;
}
.NCS0502-Y {
  background-color: #F3EFE3;
}
.NCS0500-N {
  background-color: #F1EFEB;
}
.NCS2500-N {
  background-color: #BABAB8;
}
.NCS1000-N {
  background-color: #E1E0DE;
}
</style>
